import 'jquery';
import 'mmenu-js';

jQuery('.m-header-icon.-close').hide();

document.addEventListener(
    "DOMContentLoaded", () => {
        const menu = new Mmenu(".mainNav.-mobile", {

        },
        {
            openingInterval: 0,
            transitionDuration: 0,
            offCanvas: {
                blockUI: false,
                clone: false
            }
        });

        const api = menu.API;
        jQuery(".mobileMenuToggle").click(function (event) {
            if(jQuery(this).hasClass("-active")) {
                jQuery('#header').removeClass('-navActive');
                jQuery(this).removeClass('-active');
            } else {
                jQuery('#header').addClass('-navActive');
                jQuery(this).addClass("-active");
                api.open();
            }
        });

        api.bind("close:start", function () {
            jQuery(".mobileMenuToggle").removeClass("-active");
            jQuery('#header').removeClass('-navActive');
            jQuery('.m-header-icon.-hamburger').show();
            jQuery('.m-header-icon.-close').hide();
        });
    }
);